import { template as template_d8668dee2c2c49d58facda3e82c5a6ec } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_d8668dee2c2c49d58facda3e82c5a6ec(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
