import { template as template_22095ae1e71245c6bd8c6ed2114b4839 } from "@ember/template-compiler";
const FKLabel = template_22095ae1e71245c6bd8c6ed2114b4839(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
